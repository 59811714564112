const sizes = {
  tablet: '1200px',
  phone: '600px',
  phoneSmall: '350px',
};

export const media = {
  tablet: `(max-width: ${sizes.tablet})`,
  phone: `(max-width: ${sizes.phone})`,
  phoneSmall: `(max-width: ${sizes.phoneSmall})`,
};
