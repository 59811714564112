import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import colors from '../utils/colors';
import { media } from '../utils/media';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        prismicFooter {
          data {
            contact_info {
              html
            }
            location {
              latitude
              longitude
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <ContactInfo
          dangerouslySetInnerHTML={{
            __html: data.prismicFooter.data.contact_info.html,
          }}
        />
        {/* <GoogleMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        /> */}
        <GoogleMap
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.7084169717214!2d-92.59122438375276!3d40.19330207939176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87e80e70ab805bf7%3A0x988c9c0f7e7e830c!2sGutensohn+Clinic!5e0!3m2!1sen!2sus!4v1536770262290"
          width="100%"
          height="400"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
        />
      </Wrapper>
    )}
  />
);

export default Footer;

const Wrapper = styled.footer`
  display: flex;
  min-height: 400px;
  max-height: 400px;

  @media ${media.tablet} {
    flex-direction: column;
    max-height: auto;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  min-height: 400px;
  max-height: 400px;
  padding: 2rem;
  background-color: ${colors.pickledBluewood};
  align-self: center;

  @media ${media.tablet} {
    width: 100%;
    max-height: auto;
  }

  @media ${media.phone} {
    padding: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.95);

    &::after {
      display: block;
      content: '';
      width: 50px;
      height: 4px;
      background-color: ${colors.rollingStone};
    }
  }

  ul {
    margin-left: 0;
    list-style: none;
  }

  li {
    margin: 0;
    color: rgba(255, 255, 255, 0.9);
  }
`;

const GoogleMap = styled.iframe`
  width: 60%;
  min-height: 400px;
  margin-bottom: 0;

  @media ${media.tablet} {
    width: 100%;
  }
`;
