import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import colors from '../utils/colors';
import { media } from '../utils/media';
import IntoView from '../utils/IntoView';

const SideBar = () => (
  <StaticQuery
    query={graphql`
      query SideBarQuery {
        prismicSidebar {
          data {
            sidebar_title {
              text
            }
            sidebar_content {
              html
            }
          }
        }
      }
    `}
    render={data => {
      const sidebar = data.prismicSidebar.data;
      return (
        <React.Fragment>
          <IntoView direction="fade-up">
            <SidebarWrapper>
              <SidebarHeader>
                <SidebarTitle>{sidebar.sidebar_title.text}</SidebarTitle>
              </SidebarHeader>
              <SidebarContent
                dangerouslySetInnerHTML={{
                  __html: sidebar.sidebar_content.html,
                }}
              />
            </SidebarWrapper>
          </IntoView>
        </React.Fragment>
      );
    }}
  />
);

const SidebarWrapper = styled.div`
  width: 350px;
  margin: 5rem 1rem;
  background-color: ${colors.wildSand};
  clip-path: polygon(0 0%, 90% 0, 100% 10%, 100% 100%, 0 100%);
  @media ${media.tablet} {
    width: 400px;
    margin: 2rem auto;
    clip-path: none;
  }

  @media ${media.phone} {
    width: 100%;
    margin: 2rem 0 0;
  }
`;

const SidebarHeader = styled.div`
  background-color: ${colors.mineShaft};
  padding: 1rem;
  text-transform: uppercase;
  h4 {
    margin: 0;
  }
`;

const SidebarTitle = styled.h4`
  color: ${colors.white};
  text-transform: uppercase;
`;

const SidebarContent = styled.div`
  color: ${colors.mineShaft};
  padding: 1rem;
  text-align: left;

  ul {
    margin-left: 0;
    list-style: none;
  }

  li {
    margin: 0;
  }

  a {
    color: ${colors.bigStone};
  }
`;

export default SideBar;
