import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Content = styled.div`
  margin: 0.5rem;

  iframe {
    width: 100%;
    max-width: 600px;
  }
`;

const YoutubeVideo = ({ input }) => <Content dangerouslySetInnerHTML={{ __html: input.primary.youtube_video }} />;

export default YoutubeVideo;

YoutubeVideo.propTypes = {
  input: PropTypes.object.isRequired,
};
