import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import colors from '../utils/colors';
import { media } from '../utils/media';
import IntoView from '../utils/IntoView';
import LeftArrow from '../images/LeftArrow';
import RightArrow from '../images/RightArrow';

class LatestNews extends Component {
  state = {
    activeItem: 0,
  };

  onNewsLeft = () => {
    const currentItem = this.state.activeItem;
    this.setState({
      activeItem: currentItem > 0 ? currentItem - 1 : this.props.data.length - 1,
    });
  };

  onNewsRight = () => {
    const currentItem = this.state.activeItem;
    this.setState({
      activeItem: currentItem < this.props.data.length - 1 ? currentItem + 1 : 0,
    });
  };

  render() {
    return (
      <Wrapper>
        <IntoView direction="fade-right">
          <SectionHeader>Latest News</SectionHeader>
          <NewsItems>
            {this.props.data.map((item, index) => (
              <NewsItem key={item.node.id} className={index === this.state.activeItem ? 'active' : ''}>
                <FeaturedImage src={item.node.data.featured_image.url} alt="" />
                <TextWrapper>
                  <NewsTitle>{item.node.data.title.text}</NewsTitle>{' '}
                  <NewsDescription>
                    {item.node.data.content.text.substring(0, 250)}
                    ...
                  </NewsDescription>
                  <ContinueReadingButton to={`news/${item.node.uid}`}>Continue Reading</ContinueReadingButton>
                  <ArrowWrapper>
                    <ArrowButton onClick={this.onNewsLeft}>
                      <LeftArrow />
                    </ArrowButton>
                    <ArrowButton onClick={this.onNewsRight}>
                      <RightArrow />
                    </ArrowButton>
                  </ArrowWrapper>
                </TextWrapper>
              </NewsItem>
            ))}
          </NewsItems>
        </IntoView>
      </Wrapper>
    );
  }
}

const Wrapper = styled.section`
  max-width: 1200px;
  margin: 8rem auto;
  padding: 2rem;

  @media ${media.tablet} {
    margin: 4rem auto;
  }

  @media ${media.phone} {
    padding: 1rem;
  }
`;

const SectionHeader = styled.h2`
  text-transform: uppercase;
  color: ${colors.bigStone};
  &::after {
    display: block;
    content: '';
    width: 50px;
    height: 4px;
    background-color: ${colors.rollingStone};
  }
`;

const NewsItems = styled.div`
  position: relative;
  display: flex;
  min-height: 300px;
  margin: 0 auto;

  @media ${media.tablet} {
    min-height: 550px;
  }

  @media ${media.phone} {
    min-height: 575px;
  }
`;

const NewsItem = styled.article`
  position: absolute;
  display: flex;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
  transition: opacity 0.8s ease;
  height: 100%;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

const TextWrapper = styled.div`
  margin: 0.5rem 0;
  margin-left: 2rem;

  @media ${media.tablet} {
    flex-direction: column;
    margin-left: 0;
  }
`;

const NewsTitle = styled.h3`
  display: inline-block;
`;

const NewsDescription = styled.p``;

const FeaturedImage = styled.img`
  max-width: 50%;
  max-height: 300px;
  margin: 0;
  object-fit: cover;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);

  @media ${media.tablet} {
    max-width: 100%;
    margin-bottom: 1rem;
  }
`;

const ArrowWrapper = styled.div`
  display: block;
  position: relative;
  right: 0;
  text-align: right;

  @media ${media.tablet} {
    text-align: center;
  }
`;

const ArrowButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: 33px;

  @media ${media.tablet} {
    width: 50px;
    height: 50px;
  }

  svg {
    margin: 0.25rem;
    color: ${colors.mineShaft};
    transition: all 0.1s ease-in-out;

    @media ${media.tablet} {
      width: 40px;
      height: 40px;
    }

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const ContinueReadingButton = styled(Link)`
  display: block;
  max-width: 200px;
  padding: 0.9rem 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  color: ${colors.white};
  background-color: ${colors.bigStone};
  transition: background-color 0.4s ease;

  &:hover {
    background-color: ${colors.william};
  }

  @media ${media.tablet} {
    margin-bottom: 1rem;
  }
`;

export default LatestNews;
