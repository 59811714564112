import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Link from './Link';
import colors from '../utils/colors';
import { media } from '../utils/media';

const Navigation = () => {
  const getUrl = item => {
    if (item.primary.link === null) {
      return;
    }

    if (item.primary.link.url === '/undefined') {
      return '/';
    }

    return item.primary.link.url;
  };

  return (
    <StaticQuery
      query={graphql`
        query NavigationQuery {
          prismicMainNavigation {
            data {
              nav {
                id
                primary {
                  label {
                    text
                  }
                  link {
                    url
                  }
                }
                items {
                  sub_nav_link_lable {
                    text
                  }
                  sub_nav_link {
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { nav } = data.prismicMainNavigation.data;
        return (
          <NavWrap>
            <nav aria-label="main navigation">
              <Nav>
                {nav.map(item =>
                  /* If nav item has sub items, create dropdown */
                  item.items.length ? (
                    <li key={item.id} aria-haspopup="true">
                      <NavLink key={item.id} to={getUrl(item)}>
                        {item.primary.label.text}
                      </NavLink>
                      <SubNav>
                        {item.items.map(subItem => (
                          <li>
                            <SubNavLink to={subItem.sub_nav_link.url}>
                              {subItem.sub_nav_link_lable.text}
                            </SubNavLink>
                          </li>
                        ))}
                      </SubNav>
                    </li>
                  ) : (
                    <li key={item.id}>
                      <NavLink key={item.id} to={getUrl(item)}>
                        {item.primary.label.text}
                      </NavLink>
                    </li>
                  )
                )}
              </Nav>
            </nav>
          </NavWrap>
        );
      }}
    />
  );
};

export default Navigation;

const NavWrap = styled.div`
  padding: 0 2rem;
  width: 100%;
  background-color: ${colors.pickledBluewood};
  @media ${media.tablet} {
    margin-top: 30px;
  }
`;

const Nav = styled.ul`
  display: flex;
  margin-left: 0;
  margin-bottom: 0;
  list-style-type: none;

  li {
    position: relative;
    margin-bottom: 0;
  }

  li:hover {
    ul {
      display: flex;
      flex-direction: column;
    }
  }

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

const NavLink = styled(Link)`
  display: inline-block;
  padding: 0.5rem 1rem;
  color: ${colors.white};
  font-family: 'Montserrat';
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.bigStone};
  }

  @media ${media.tablet} {
    display: block;
    padding: 0.75em 15px;
    line-height: 1em;
    font-size: 1em;
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid ${colors.white};
  }
`;

const SubNav = styled.ul`
  display: none;
  position: absolute;
  left: 0;
  margin: 0;
  padding: 0.5rem 1rem;
  background-color: ${colors.hoki};
  z-index: 1000;
  list-style-type: none;

  @media ${media.tablet} {
    width: 100%;
    position: relative;
    border-bottom: 1px solid ${colors.white};
  }

  li {
    padding: 3px;
  }
`;

const SubNavLink = styled(Link)`
  display: block;
  color: ${colors.white};
  font-family: 'Montserrat';
  font-size: 16px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: 0.3s all ease;
  white-space: nowrap;

  &:hover {
    border-bottom: 2px solid ${colors.white};
  }

  @media ${media.tablet} {
    line-height: 1.3rem;
    white-space: pre-wrap;
  }
`;
