import React from 'react';
import styled from 'styled-components';
import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => (
  <GlobalWrapper>
    <SEO />
    <Header />
    {children}
    <Footer />
  </GlobalWrapper>
);

const GlobalWrapper = styled.div``;

export default Layout;
