import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import colors from '../utils/colors';
import { media } from '../utils/media';

const Hero = ({ data }) => (
  <Wrapper>
    <Punchline>{data.hero_caption.text}</Punchline>
    <HeroImage src={data.hero_image.url} alt={data.hero_image.alt} />
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 60vh;

  &::after {
    position: absolute;
    left: 0;
    z-index: 1;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.7));
  }

  @media ${media.tablet} {
    max-height: 40vh;
  }
`;

const Punchline = styled.h2`
  position: absolute;
  z-index: 10;
  align-self: center;
  max-width: 50%;
  margin-left: 2rem;
  padding: 2rem;
  line-height: 2.75rem;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
  background-color: rgba(255,255,255,0.6);
  color: ${colors.bigStone};
  @media ${media.tablet} {
    bottom: 20%;
    margin: 0 1rem;
    max-width: 100%;
  }
  @media ${media.phone} {
    margin: 1rem;
    bottom: 0;
    padding: 1rem;
    max-width: 100%;
    line-height: 1.75rem;
    font-size: 26px;
    clip-path: none;
  }
}
`;

const HeroImage = styled.img`
  height: 60vh;
  width: 100%;
  object-fit: cover;

  @media ${media.tablet} {
    max-height: 40vh;
  }
`;

export default Hero;
