import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import colors from '../utils/colors';
import { media } from '../utils/media';
import IntoView from '../utils/IntoView';

const FocusTabs = ({ data }) => (
  <Wrapper>
    {data.focus_tabs.map((tab, index) => (
      <IntoView className="animated-tab" direction="fade-right" delay={100 * index} key={tab.focus_tab_title.text}>
        <Tab className="tab">
          <TabLink to={tab.focus_tab_link === null ? '' : tab.focus_tab_link.url}>
            <TabTitle>{tab.focus_tab_title.text}</TabTitle>
            <TabDescription>{tab.focus_tab_description.text}</TabDescription>
          </TabLink>
        </Tab>
      </IntoView>
    ))}
  </Wrapper>
);

const Wrapper = styled.ul`
  position: relative;
  display: flex;
  flex-basis: 100%;
  width: 100%;
  margin: 0;
  list-style: none;
  box-shadow: 2px 3px 14px rgba(0, 0, 0, 0.3);
  overflow-x: hidden;

  @media ${media.tablet} {
    flex-direction: column;
  }

  .aos-animate {
    width: 25%;

    @media ${media.tablet} {
      width: 100%;
    }
  }

  .animated-el:nth-child(1) .tab {
    background-color: ${colors.hoki};
  }
  .animated-el:nth-child(2) .tab {
    background-color: ${colors.william};
  }
  .animated-el:nth-child(3) .tab {
    background-color: ${colors.pickledBluewood};
  }
  .animated-el:nth-child(4) .tab {
    background-color: ${colors.bigStone};
  }
`;

const Tab = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 250px;
  width: 100%;
  padding: 2rem;
  margin: 0;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: ${colors.white};
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  &:hover :after {
    left: 0;
    right: 0;
  }

  @media ${media.tablet} {
    min-height: 200px;
    padding: 1rem;
    margin-bottom: 0;
  }
`;

const TabLink = styled(Link)`
  text-decoration: none;
`;

const TabTitle = styled.h3`
  color: ${colors.white};
  text-transform: uppercase;
`;

const TabDescription = styled.p`
  max-width: 350px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 300;
  line-height: 1.2rem;
`;

export default FocusTabs;
