import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import colors from '../utils/colors';
import { media } from '../utils/media';

const Banner = ({ data }) => (
  <Wrapper>
    <PageTitle>{data.page_title.text}</PageTitle>
    <BannerImage src={data.banner_image.url} alt={data.banner_image.alt} />
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  &::after {
    position: absolute;
    left: 0;
    z-index: 1;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.7));
    clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);

    @media ${media.phone} {
      clip-path: none;
    }
  }
`;

const PageTitle = styled.h1`
  position: absolute;
  z-index: 10;
  align-self: center;
  max-width: 50%;
  padding: 2rem 5rem 2rem 2rem;
  line-height: 2.75rem;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 85% 100%, 0 100%);
  background-color: rgba(255,255,255,0.6);
  color: ${colors.bigStone};

  @media ${media.tablet} {
    margin: 0 1rem;
    padding: 1rem 2rem 1rem 1rem;
    margin-left: 0;
    max-width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 60%, 90% 100%, 0 100%);
  }
  @media ${media.phone} {
    margin: 1rem;
    margin-left: 0;
    padding: 1.5rem;
    max-width: 100%;
    line-height: 2.5rem;
    clip-path: none;
  }

  @media ${media.phoneSmall} {
    font-size: 2.5rem;
  }
}
`;

const BannerImage = styled.img`
  max-height: 350px;
  min-height: 200px;
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);

  @media ${media.phone} {
    clip-path: none;
  }
`;

export default Banner;
