import React from 'react';
import styled from 'styled-components';
import colors from '../utils/colors';
import Logo from './Logo';
import Menu from './Menu';
import { media } from '../utils/media';

const Header = () => (
  <HeaderWrapper>
    <Logo />
    {/* <StillOptiButton
      href="https://www.stillopti.org/"
      target="_blank"
      title="Still Opti"
    >
      Still Opti &rarr;
    </StillOptiButton> */}
    <Menu />
  </HeaderWrapper>
);

const HeaderWrapper = styled.header``;

const StillOptiButton = styled.a`
  position: absolute;
  right: 2rem;
  top: 1.2rem;
  padding: 0.4rem 0.8rem;
  text-decoration: none;
  border: 1px solid ${colors.bigStone};
  color: ${colors.bigStone};

  @media ${media.tablet} {
    padding: 0.2rem 0.6rem;
  }
`;

export default Header;
