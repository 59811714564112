import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BodyText, ImageGallery, YoutubeVideo } from '../slices';
import { media } from '../utils/media';

const Content = styled.div`
  @media ${media.tablet} {
    margin: 0 auto;
    padding: 1rem 0;
  }
`;

export default function SliceZone(props) {
  const { allSlices } = props;
  const slice = allSlices.map(s => {
    switch (s.slice_type) {
      // These are the API IDs of the slices
      case 'text':
        return <BodyText key={s.id} input={s} />;
      case 'image_gallery':
        return <ImageGallery key={s.id} input={s} />;
      case 'youtube_video':
        return <YoutubeVideo key={s.id} input={s} />;
      default:
        return null;
    }
  });
  return <Content>{slice}</Content>;
}

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
};
