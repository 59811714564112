import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import colors from '../utils/colors';
import { media } from '../utils/media';
import IntoView from '../utils/IntoView';

const About = ({ data }) => (
  <Wrapper>
    <IntoView direction="fade-left">
      <InnerWrapper>
        <SectionHeader>{data.about_title.text}</SectionHeader>
        <AboutCard>
          <AboutDescription dangerouslySetInnerHTML={{ __html: data.about.html }} />
        </AboutCard>
      </InnerWrapper>
    </IntoView>
  </Wrapper>
);

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  background-color: ${colors.mineShaft};
  padding: 8rem 2rem;
  margin-bottom: 12rem;

  @media ${media.tablet} {
    padding: 4rem 2rem;
  }

  @media ${media.phone} {
    padding: 4rem 1rem;
  }
`;

const InnerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionHeader = styled.h2`
  color: ${colors.white};
  text-transform: uppercase;

  &::after {
    display: block;
    content: '';
    width: 50px;
    height: 4px;
    background-color: ${colors.hoki};
  }
`;

const AboutDescription = styled.div`
  color: ${colors.white};

  p {
    color: rgba(255, 255, 255, 0.9);
  }
`;

const AboutCard = styled.div`
  padding: 2rem 4rem;
  margin-bottom: -12rem;
  background-color: ${colors.hoki};
  box-shadow: 2px 3px 14px rgba(0, 0, 0, 0.3);
  @media ${media.tablet} {
    padding: 1.5rem;
  }

  h3 {
    margin: 2rem auto 1rem;
    color: rgba(255, 255, 255, 0.95);
  }

  ul {
    columns: 2;

    @media ${media.tablet} {
      columns: 1;
    }
  }
  li {
    margin-bottom: 0;
  }
`;

export default About;
