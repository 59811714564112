/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import config from '../../config/SiteConfig';

const SEO = props => {
  const { newsItem, newsPath, newsSEO, description, keywords } = props;
  let title;
  let image;
  let newsURL;
  let newsDescription;
  const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix;
  if (newsSEO) {
    title = newsItem.meta_title; // eslint-disable-line prefer-destructuring
    newsDescription = newsItem.meta_description;
    image = config.siteBanner;
    newsURL = config.siteUrl + realPrefix + newsItem;
  } else {
    title = config.siteTitle;
    newsDescription = config.siteDescription;
    image = config.siteBanner;
  }
  image = config.siteUrl + realPrefix + image;
  const blogURL = config.siteUrl + config.pathPrefix;
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    },
  ];

  return (
    <Helmet>
      <title>{config.siteTitle}</title>
      <meta name="description" content={newsDescription ? newsDescription : description} />
      <meta name="keywords" content={keywords ? keywords : 'ATSU, Still OPTI Residency, residency, medical school'} />
      <meta name="image" content={image} />
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
      <meta property="og:locale" content="de_DE" />
      <meta property="og:site_name" content={config.ogSiteName} />
      <meta property="og:url" content={newsSEO ? newsURL : blogURL} />
      {newsSEO ? <meta property="og:type" content="article" /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={newsDescription ? newsDescription : description} />
      <meta property="og:image" content={image} />
      <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ''} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ''} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={config.siteUrl} />
      <meta name="twitter:description" content={newsDescription ? newsDescription : description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  newsItem: PropTypes.object,
  newsPath: PropTypes.string,
  newsSEO: PropTypes.bool,
  description: PropTypes.string,
  keywords: PropTypes.string,
};
