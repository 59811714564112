import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class IntoView extends Component {
  static defaultProps = {
    direction: 'fade-down',
    delay: 100,
    disable: 'tablet',
  };

  componentDidMount = () => {
    AOS.init({
      disable: 'mobile',
    });
  };

  render() {
    const { children, direction, delay } = this.props;
    return (
      <div className="animated-el" data-aos={direction} data-aos-once="true" data-aos-delay={delay}>
        {children}
      </div>
    );
  }
}

IntoView.propTypes = {
  direction: PropTypes.string,
};
