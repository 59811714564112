import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../utils/colors';
import { media } from '../utils/media';
import IntoView from '../utils/IntoView';

const History = ({ data }) => (
  <Wrapper>
    <IntoView direction="fade-right">
      <SectionHeader>{data.history_title.text}</SectionHeader>
      <AboutWrapper>
        <AboutDescription dangerouslySetInnerHTML={{ __html: data.history.html }} />
        <HistoryImage src={data.history_image.url} alt="" />
      </AboutWrapper>
    </IntoView>
  </Wrapper>
);

History.propTypes = {
  data: PropTypes.shape({
    history: PropTypes.object,
    history_title: PropTypes.string,
    history_image: PropTypes.string,
  }).isRequired,
};

const Wrapper = styled.section`
  max-width: 1200px;
  margin: 8rem auto;
  padding: 2rem;

  @media ${media.tablet} {
    margin: 4rem auto;
  }

  @media ${media.phone} {
    padding: 1rem;
  }
`;

const SectionHeader = styled.h2`
  text-transform: uppercase;
  color: ${colors.bigStone};
  &::after {
    display: block;
    content: '';
    width: 50px;
    height: 4px;
    background-color: ${colors.rollingStone};
  }
`;

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media ${media.tablet} {
    flex-direction: column;
  }
`;

const AboutDescription = styled.div``;

const HistoryImage = styled.img`
  max-height: 540px;
  margin-left: 2rem;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);

  @media ${media.tablet} {
    margin-left: 0;
  }
`;

export default History;
