// Names generated from: http://chir.ag/projects/name-that-color

export const white = '#FFFFFF';
export const black = '#000000';
export const bigStone = '#193441';
export const pickledBluewood = '#284654';
export const william = '#3E606F';
export const hoki = '#608596';
export const pewter = '#91AA9D';
export const tana = '#D1DBBD';
export const mineShaft = '#333333';
export const rollingStone = '#74838A';
export const dustyGray = '#979797';
export const wildSand = '#F4F4F4';

export default {
  white,
  black,
  bigStone,
  pickledBluewood,
  william,
  hoki,
  pewter,
  tana,
  mineShaft,
  rollingStone,
  dustyGray,
  wildSand,
};
