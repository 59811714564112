import React, { Component } from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import colors from '../utils/colors';
import { media } from '../utils/media';

export default class Menu extends Component {
  state = {
    open: false,
  };

  toggleMenu = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    return (
      <React.Fragment>
        <MenuButton onClick={this.toggleMenu} className={this.state.open ? 'open' : ''}>
          <MenuBurger className={this.state.open ? 'open' : ''} />
        </MenuButton>
        <MenuWrapper className={this.state.open ? 'open' : ''}>
          <MenuBurgerClose onClick={this.toggleMenu} className={this.state.open ? 'open' : ''} />
          <Navigation />
        </MenuWrapper>
        <Backdrop onClick={this.toggleMenu} className={this.state.open ? 'open' : ''} />
      </React.Fragment>
    );
  }
}

const MenuWrapper = styled.div`
  @media ${media.tablet} {
    position: absolute;
    left: -250px;
    width: 250px;
    top: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: visible;
    transition: left 0.3s ease, box-shadow 0.3s ease;
    z-index: 999;
    background-color: ${colors.pickledBluewood};

    &.open {
      position: relative;
      z-index: 1000;
      left: 0;
      outline: none;
      box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    }

    @supports (position: fixed) {
      &.open {
        position: fixed;
      }
    }
  }
`;

const Backdrop = styled.a`
  display: none;
  &.open {
    z-index: 1001;
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    background: #000;
    background: rgba(0, 0, 0, 0.85);
    cursor: default;
  }
`;

const MenuButton = styled.a`
  display: none;

  @media ${media.tablet} {
    display: inline-block;
    position: absolute;
    right: 1rem;
    top: 1.25rem;
    cursor: pointer;
    align-self: center;
    width: 45px;
    height: 43px;
    background: none;
    border: none;
    padding: 7px;
    margin-left: auto;
    transition: all 0.3s ease;
    transform: translate3d(0, 0, 0);
  }
`;

const MenuBurger = styled.span`
  display: block;
  width: 30px;
  height: 4px;
  margin: 8px auto;
  background-color: ${colors.rollingStone};
  border-radius: 1px;
  transition: all 0.3s ease;

  &.open {
    background-color: transparent;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    width: 30px;
    height: 4px;
    border-radius: 1px;
    background-color: ${colors.rollingStone};
    position: absolute;
    transition: all 0.3s ease;
  }
  &::before {
    margin: 5px 0;
    top: 0px;
  }
  &::after {
    margin: 10px 0;
    top: 15px;
  }
  &.open::before {
    background-color: ${colors.rollingStone};
    top: 20px;
    margin: 0;
    transform: rotate(45deg);
  }
  &.open::after {
    background-color: ${colors.rollingStone};
    top: 20px;
    margin: 0;
    transform: rotate(-45deg);
  }
`;

const MenuBurgerClose = styled.span`
  display: none;

  @media ${media.tablet} {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    margin: 8px 5px 8px auto;
    background-color: ${colors.rollingStone};
    border-radius: 1px;
    transition: all 0.3s ease;
    cursor: pointer;

    &.open {
      background-color: transparent;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      width: 30px;
      height: 4px;
      border-radius: 1px;
      background-color: ${colors.rollingStone};
      position: absolute;
      transition: all 0.3s ease;
    }
    &::before {
      margin: 5px 0;
      top: 0px;
    }
    &::after {
      margin: 10px 0;
      top: 15px;
    }
    &::before {
      background-color: ${colors.rollingStone};
      top: 15px;
      margin: 0;
      transform: rotate(45deg);
    }
    &::after {
      background-color: ${colors.rollingStone};
      top: 15px;
      margin: 0;
      transform: rotate(-45deg);
    }
  }
`;
