import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../utils/media';

const closeModalSvg = `<svg
x="0px"
y="0px"
width="24px"
height="24px"
viewBox="0 0 31.11 31.11"
enableBackground="new 0 0 31.11 31.11"
>
<polygon
  fill=white
  points="31.11,1.41 29.7,0 15.56,14.14 1.41,0 0,1.41 14.14,15.56 0,29.7 1.41,31.11 15.56,16.97   29.7,31.11 31.11,29.7 16.97,15.56 "
/>
</svg>`;

function createLightbox(target) {
  const imageContainer = document.createElement('div');
  imageContainer.classList.add('image-container');
  const image = document.createElement('img');
  image.src = target.src;

  imageContainer.innerHTML = closeModalSvg;
  imageContainer.appendChild(image);

  document.getElementById('image-gallery').appendChild(imageContainer);

  return imageContainer;
}

function createEventHandlers(imageContainer) {
  imageContainer.addEventListener('click', () => {
    imageContainer.remove();
  });

  document.addEventListener('keydown', e => {
    if (e.keyCode === 27) {
      // ESC
      imageContainer.remove();
    }
  });
}

class ImageGallery extends Component {
  state = {
    images: this.props.input.items.map(image => ({
      url: image.gallery_image.url,
      alt: image.gallery_image.alt,
    })),
  };

  toggleImage = e => {
    e.preventDefault();
    const imageContainer = createLightbox(e.target);
    createEventHandlers(imageContainer);
  };

  render() {
    return (
      <Content id="image-gallery">
        {this.state.images.map(image => (
          <a href={image.url} onClick={e => this.toggleImage(e)} key={image.url}>
            <img className="gallery-image" alt={image.alt} src={image.url} />
          </a>
        ))}
      </Content>
    );
  }
}

const Content = styled.div`
  margin: 1rem auto 3rem;
  .gallery-image {
    max-width: 200px;
    margin: 0.5rem;
    cursor: pointer;

    &.open {
      position: absolute;
    }
  }
  .image-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    padding: 10% 20%;

    @media ${media.tablet} {
      padding: 10% 5%;
    }

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      margin: 0 auto;
    }

    svg {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
  }
`;

export default ImageGallery;

ImageGallery.propTypes = {
  input: PropTypes.object.isRequired,
};
